import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: '/index',
    component: () => import("@/views/home/index.vue"),
    children: [{
      path: 'index',
      name: 'index',
      component: () => import("@/views/home/index/index.vue"),
      meta: {
        title: '检测服务平台-首页'
      },
    },
    {
      path: 'devDocument',
      name: 'devDocument',
      component: () => import("@/views/home/devDocument/index.vue"),
      meta: {
        title: '检测服务平台-开放文档'
      },
    },
    {
      path: 'settleIn',
      name: 'settleIn',
      component: () => import("@/views/home/settleIn/index.vue"),
      meta: {
        title: '检测服务平台-入驻申请'
      },
    },
    ]
  }
]

const router = createRouter({
  // hash模式的路由
  history: createWebHashHistory(),
  routes
})

// 路由拦截，形成进度条
router.beforeEach((to, from, next) => {
  // 标题
  document.title = String(to.meta.title || '');
  next()
})
router.afterEach(() => {
  // 置顶
  window.scrollTo(0, 0)
})

export default router
